import axios from "axios";
//import settings from "../../cfg/settings";
//import Vue from "vue";
const baseUrl = window.__env.baseURL;

const apiClient = axios.create({
  baseURL: baseUrl + "Routing",
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export default {
  ////////////////////////////// License //////////////////////////////
  getLicense() {
    var licenseClient = axios.create({
      baseURL: baseUrl,
      withCredentials: false,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return licenseClient.get("/License/Get");
  },
  ////////////////////////////// License //////////////////////////////
  getAbledSettings() {
    var uiClient = axios.create({
      baseURL: baseUrl,
      withCredentials: false,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return uiClient.get("/UI/Get");
  },
  ////////////////////////////// Jobs //////////////////////////////
  getJobs() {
    return apiClient.get("/jobs");
  },
  getJobsBySearch(jobNumber, searchOption) {
    return apiClient.get("/jobs/search", {
      params: {
        jobNumber,
        searchOption,
      },
    });
  },
  getJob(jobNumber, jobItemNumber) {
    return apiClient.get("/job/getJob", {
      params: {
        jobNumber,
        jobItemNumber,
      },
    });
  },
  saveJob(job) {
    return apiClient.post("SaveJob", job);
  },

  ////////////////////////////// RoutingTypes //////////////////////////////
  getRoutingTypes(onlyActive) {
    return apiClient.get("/RoutingTypes", {
      params: {
        onlyActive,
      },
    });
  },
  saveRoutingTypes(routingTypes) {
    return apiClient.post("SaveRoutingTypes", routingTypes);
  },

  ////////////////////////////// Routings //////////////////////////////
  getRouting(id) {
    return apiClient.get("/Routing/" + id);
  },

  ////////////////////////////// RoutingTemplates //////////////////////////////
  getRoutingTemplates(routingTypeId, onlyActive) {
    return apiClient.get("/RoutingTemplates", {
      params: {
        onlyActive,
        routingTypeId,
      },
    });
  },
  getRoutingTemplate(id) {
    return apiClient.get("/RoutingTemplate/" + id);
  },
  saveRoutingTemplate(routingTemplate) {
    return apiClient.post("SaveRoutingTemplate", routingTemplate);
  },

  ////////////////////////////// RoutingStepTemplates //////////////////////////////
  getRoutingStepTemplates() {
    return apiClient.get("/RoutingStepTemplates");
  },
  saveRoutingStepTemplate(routingStepTemplate) {
    return apiClient.post("SaveRoutingStepTemplate", routingStepTemplate);
  },

  ////////////////////////////// Resources //////////////////////////////
  getResources() {
    return apiClient.get("/Resources");
  },
  saveResources(resources) {
    return apiClient.post("SaveResources", resources);
  },

  ////////////////////////////// Validation //////////////////////////////
  validateCellAndOperationCode(cellCode, operationCode, routingStepTemplateId) {
    return apiClient.get("/ValidateCellAndOperationCode", {
      params: {
        cellCode,
        operationCode,
        routingStepTemplateId,
      },
    });
  },
  validateCellCode(cellCode, routingStepTemplateId) {
    return apiClient.get("/ValidateCellCode", {
      params: {
        cellCode,
        routingStepTemplateId,
      },
    });
  },
  validateOperationCode(operationCode, routingStepTemplateId) {
    return apiClient.get("/ValidateOperationCode", {
      params: {
        operationCode,
        routingStepTemplateId,
      },
    });
  },
  validateResourceCode(resourceCode, resourceId) {
    return apiClient.get("/ValidateResourceCode", {
      params: {
        resourceCode,
        resourceId,
      },
    });
  },
};
