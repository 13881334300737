<template>
  <v-snackbar v-model="saveSnackbar" :multi-line="true" color="error" top>
    {{ errorDetail }}
    <template v-slot:action="{ attrs }">
      <v-btn icon v-bind="attrs" @click="saveSnackbar = false">
        <v-icon> mdi-close </v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  data() {
    return {
      saveSnackbar: false,
      snackbarSuccess: true,
    };
  },
  computed: {
    errorDetail() {
      return this.error ? this.error.response.data.detail : "";
    },
    error() {
      return this.$store.state.error;
    },
  },
  watch: {
    error() {
      this.saveSnackbar = true;
    },
  },
};
</script>

<style lang="scss" scoped></style>
