<template>
  <v-container>
    <v-row>
      <v-col cols="5" sm="4" md="3">
        <v-card outlined>
          <v-card-title>
            <v-icon large>mdi-desktop-classic</v-icon>
            <v-spacer></v-spacer>
            You
            <v-spacer></v-spacer>
            <v-icon color="success" large>mdi-check-decagram</v-icon>
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="1" v-for="index in spaceForDots" :key="index">
        <v-card class="transparent mx-0 px-0" flat>
          <v-card-title class="mx-0 px-0">
            <v-spacer></v-spacer>
            <v-icon large>mdi-arrow-right</v-icon>
            <!-- <v-icon large>mdi-circle-small</v-icon> -->
            <v-spacer></v-spacer>
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="5" sm="4" md="3">
        <v-card outlined>
          <v-card-title>
            <v-icon large>mdi-server-off</v-icon>
            <v-spacer></v-spacer>
            Server
            <v-spacer></v-spacer>
            <v-icon color="error" large>mdi-alert-decagram</v-icon>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card color="error">
          <v-card-title>
            <v-spacer></v-spacer>
            OOPS!
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-title style="word-break: break-word">
            The frontend didn't seem to connect with the backend. Please try
            again later. If this error keeps showing, please contact the
            developer team.
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  computed: {
    spaceForDots() {
      if (this.$vuetify.breakpoint.xsOnly) {
        return 2;
      }
      if (this.$vuetify.breakpoint.smOnly) {
        return 4;
      }
      return 6;
    },
  },
};
</script>

<style scoped></style>
