export default {
  columns: [
    {
      index: 0,
      // dbName: "Routing Steps Template",
      name: "Routing Steps",
      tasks: [],
    },
    {
      index: 1,
      // dbName: "Routing Steps",
      name: "Timeline",
      tasks: [
        /*
      //     {
      //       id: "3",
      //       cellName: "string",
      //       operationName: "string",
      //       processName: "string",
      //       tactTime: 0,
      //       color: "string",
      //       image: "string",
      //       sortKey: 0,
      //       routingXRoutingStepViewModel: {
      //         routingViewModelId: "2",
      //         routingStepViewModelId: "3",
      //         routingStepViewModel: {},
      //         routingViewModel: {},
      //       },
      //     },
      //     {
      //       id: "4",
      //       cellName: "string",
      //       operationName: "string",
      //       processName: "string",
      //       tactTime: 0,
      //       color: "string",
      //       image: "string",
      //       sortKey: 0,
      //       routingXRoutingStepViewModel: {
      //         routingViewModelId: "2",
      //         routingStepViewModelId: "4",
      //         routingStepViewModel: {},
      //         routingViewModel: {},
      //       },
      //     },
    */
      ],
    },
  ],
  routingTypes: null,
  job: null,
  // id: "1",
  // jobNumber: "string",
  // description: "string",
  // routing: {
  //   id: "2",
  //   name: "string",
  //   jobId: "1",
  //   routingSteps: [
  //     {
  //       id: "3",
  //       cellName: "string",
  //       operationName: "string",
  //       processName: "string",
  //       tactTime: 0,
  //       color: "string",
  //       image: "string",
  //       sortKey: 0,
  //       routingXRoutingStepViewModel: {
  //         routingViewModelId: "2",
  //         routingStepViewModelId: "3",
  //         routingStepViewModel: {},
  //         routingViewModel: {},
  //       },
  //     },
  //     {
  //       id: "4",
  //       cellName: "string",
  //       operationName: "string",
  //       processName: "string",
  //       tactTime: 0,
  //       color: "string",
  //       image: "string",
  //       sortKey: 0,
  //       routingXRoutingStepViewModel: {
  //         routingViewModelId: "2",
  //         routingStepViewModelId: "4",
  //         routingStepViewModel: {},
  //         routingViewModel: {},
  //       },
  //     },
  //   ],
  // },
};
