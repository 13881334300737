import Vue from "vue";
import Router from "vue-router";
import store from "../store";

Vue.use(Router);

const ifCanJobs = (to, from, next) => {
  if (!store.getters.getAbledJobsSetting) {
    next({ name: "start" });
  } else {
    next();
    return;
  }
};
const ifCanTemplates = (to, from, next) => {
  if (!store.getters.getAbledTemplatesSetting) {
    next({ name: "start" });
  } else {
    next();
    return;
  }
};
const ifCanProposSettings = (to, from, next) => {
  if (!store.getters.getAbledProposSettingsSetting) {
    next({ name: "start" });
  } else {
    next();
    return;
  }
};
const ifCanCompanySettings = (to, from, next) => {
  if (!store.getters.getAbledCompanySettingsSetting) {
    next({ name: "start" });
  } else {
    next();
    return;
  }
};

export default new Router({
  //mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "start",
      component: () => import("@/views/Start.vue"),
    },
    {
      path: "/job",
      name: "job",
      component: () => import("@/views/Jobs/Job.vue"),
      beforeEnter: ifCanJobs,
    },
    {
      path: "/job/board",
      name: "board",
      component: () => import("@/views/Jobs/Board.vue"),
      beforeEnter: ifCanJobs,
    },
    {
      path: "/job/routingTypes",
      name: "job/routingTypes",
      component: () => import("@/views/Jobs/JobRoutingTypes.vue"),
      beforeEnter: ifCanJobs,
    },
    {
      path: "/job/useRoutingTemplate",
      name: "useRoutingTemplate",
      component: () => import("@/views/Jobs/UseRoutingTemplate.vue"),
      beforeEnter: ifCanJobs,
    },
    {
      path: "/job/routingTemplates",
      name: "job/routingTemplates",
      component: () => import("@/views/Jobs/RoutingTemplates.vue"),
      beforeEnter: ifCanJobs,
    },

    {
      path: "/templates",
      name: "templates",
      redirect: { name: "templates/routingTypes" },
      beforeEnter: ifCanTemplates,
    },
    {
      path: "/templates/routingTemplates",
      name: "templates/routingTemplates",
      component: () => import("@/views/Templates/RoutingTemplates.vue"),
      beforeEnter: ifCanTemplates,
    },
    {
      path: "/templates/routingTypes",
      name: "templates/routingTypes",
      component: () => import("@/views/Templates/TemplateRoutingTypes.vue"),
      beforeEnter: ifCanTemplates,
    },
    {
      path: "/templates/board",
      name: "templates/board",
      component: () => import("@/views/Templates/TemplateBoard.vue"),
      beforeEnter: ifCanTemplates,
    },

    {
      path: "/beheer",
      name: "beheer",
      component: () => import("@/views/Beheer/Beheer.vue"),
      beforeEnter: ifCanProposSettings,
    },
    {
      path: "/beheer/cellen",
      name: "beheer/routingStepTemplates",
      component: () => import("@/views/Beheer/RoutingStepTemplates.vue"),
      beforeEnter: ifCanProposSettings,
    },
    {
      path: "/beheer/types",
      name: "beheer/routingTypes",
      component: () => import("@/views/Beheer/RoutingTypes.vue"),
      beforeEnter: ifCanProposSettings,
    },

    {
      path: "/companySettings",
      name: "companySettings",
      component: () => import("@/views/CompanySettings/CompanySettings.vue"),
      beforeEnter: ifCanCompanySettings,
    },
  ],
});
