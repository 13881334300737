<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card color="error">
          <v-card-title>ERROR</v-card-title>
          <v-card-title>
            Your license has expired, please purchase one.
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>

<style scoped></style>
