import Vue from "vue";
import Vuex from "vuex";
import defaultBoard from "./default-board";
import { uuid } from "../utils";

Vue.use(Vuex);

const board = defaultBoard;

export default new Vuex.Store({
  state: {
    board,
    error: { response: { data: { detail: "" } } },
    jobs: null,
    abledSettings: {
      enableJobs: false,
      enableTemplates: false,
      enableProposSettings: false,
      enableCompanySettings: false,
    },
  },
  getters: {
    getJobs(state) {
      return state.jobs;
    },
    getAbledJobsSetting(state) {
      return state.abledSettings.enableJobs;
    },
    getAbledProposSettingsSetting(state) {
      return state.abledSettings.enableProposSettings;
    },
    getAbledTemplatesSetting(state) {
      return state.abledSettings.enableTemplates;
    },
    getAbledCompanySettingsSetting(state) {
      return state.abledSettings.enableCompanySettings;
    },
    getTask(state) {
      return (id) => {
        for (const column of state.board.columns) {
          for (const task of column.tasks) {
            if (task.id === id) {
              return task;
            }
          }
        }
      };
    },
    jobIsEmpty(state) {
      return state.board.job == null;
    },
    getRoutingTypesLength(state) {
      if (state.board.routingTypes) return state.board.routingTypes.length;
      else return 0;
    },
  },
  mutations: {
    /////////////////////////////////////////////////////// Error
    SAVE_ERROR(state, { error }) {
      console.log(error);
      state.error = error;
    },
    /////////////////////////////////////////////////////// AbledSettings
    SAVE_ABLED_SETTINGS(state, { abledSettings }) {
      //console.log(error);
      state.abledSettings = abledSettings;
    },
    /////////////////////////////////////////////////////// Jobs
    SAVE_JOBS(state, { jobs }) {
      // console.log(jobs);
      state.jobs = jobs;
    },
    SET_JOB(state, { job }) {
      state.board.job = job;
      //// resetting routingSteps so you can start new
      this.commit("EMPTY_ROUTING");
      state.board.columns[1].isActive = true;
      state.board.columns[1].id = undefined;
      state.board.columns[1].routingType = null;
      state.board.columns[1].routingTypeId =
        "00000000-0000-0000-0000-000000000000";
      state.board.columns[1].forecast = false;
      if (job) {
        state.board.columns[1].jobNumber = job.jobNumber;
        state.board.columns[1].jobItemNumber = job.jobItemNumber;
      } else {
        state.board.columns[1].jobNumber = null;
        state.board.columns[1].jobItemNumber = null;
      }
      state.board.columns[1].routingTemplateId =
        "00000000-0000-0000-0000-000000000000";
    },
    SET_ROUTINGTYPES(state, { response }) {
      state.board.routingTypes = response.data;
    },
    SET_ROUTINGSTEPTEMPLATES(state, { response }) {
      state.board.columns[0].tasks = response.data;
      for (const task of state.board.columns[0].tasks) {
        if (task.resourceId == "00000000-0000-0000-0000-000000000000") {
          for (const resource of task.resources) {
            if (resource.name === task.operationName) {
              task.resourceId = resource.id;
            }
          }
          task.resources.sort(function (a, b) {
            var nameA = a.name.toUpperCase(); // ignore upper and lowercase
            var nameB = b.name.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }

            // names must be equal
            return 0;
          });
        }
      }
      state.board.columns[1].tasks.forEach((routingStep) => {
        if (
          routingStep.routingStepTemplateId ==
          "00000000-0000-0000-0000-000000000000"
        ) {
          const sortKey = routingStep.sortKey;
          const routingStepTemplate = state.board.columns[0].tasks.find(
            ({ cellName, operationName }) =>
              cellName === routingStep.cellName &&
              operationName === routingStep.operationName
          );
          routingStep.color = routingStepTemplate.color;
          routingStep.coolingTime = routingStepTemplate.coolingTime;
          routingStep.id = routingStepTemplate.id;
          routingStep.idTask = routingStepTemplate.idTask;
          routingStep.image = routingStepTemplate.image;
          routingStep.processName = routingStepTemplate.processName;
          routingStep.resources = routingStepTemplate.resources;
          routingStep.resourceId = routingStepTemplate.resources[0].id;
          routingStep.routingStepTemplateId = routingStepTemplate.id;
          routingStep.tactTime = routingStepTemplate.tactTime;
          routingStep.sortKey = sortKey;
        }
      });
    },
    SET_ROUTING(state, { routing }) {
      state.board.columns[1].tasks = routing.tasks;
      state.board.columns[1].tasks.sort(function (a, b) {
        return a.sortKey - b.sortKey;
      });
      for (const task of state.board.columns[1].tasks) {
        if (task.idTask == undefined) {
          task.idTask = uuid();
        }
      }
      state.board.columns[1].name = routing.name;
      state.board.columns[1].id = routing.id;
      state.board.columns[1].isActive = routing.isActive;
      state.board.columns[1].routingTemplateId = routing.routingTemplateId;
      state.board.columns[1].routingTypeId = routing.routingTypeId;
      state.board.columns[1].routingType = routing.routingType;
      state.board.columns[1].jobNumber = routing.jobNumber;
      state.board.columns[1].jobItemNumber = routing.jobItemNumber;
      state.board.columns[1].forecast = routing.forecast;
    },
    COPY_ROUTING(state, { routing }) {
      let copiedRouting = {
        id: "00000000-0000-0000-0000-000000000000",
        routingTemplateId: routing.id,
        isActive: routing.isActive,
        jobNumber: routing.jobNumber,
        jobItemNumber: routing.jobItemNumber,
        name: routing.name,
        routingTypeId: routing.routingTypeId,
        routingType: routing.routingType,
        forecast: routing.forecast,
        tasks: JSON.parse(JSON.stringify(routing.routingStepTemplates)),
      };
      this.commit("SET_ROUTING", {
        routing: copiedRouting,
      });
    },
    EMPTY_ROUTING(state) {
      state.board.columns[1].name = "Timeline";
      state.board.columns[1].tasks = [];
    },
    PREPARE_SET_ROUTING(state, { response }) {
      let routing = response.data;
      let copiedRouting = {
        id: routing.id,
        routingTemplateId: routing.routingTemplateId,
        isActive: routing.isActive,
        name: routing.name,
        jobNumber: routing.jobNumber,
        jobItemNumber: routing.jobItemNumber,
        routingTypeId: routing.routingTypeId,
        forecast: routing.forecast,
        tasks: JSON.parse(JSON.stringify(routing.routingSteps)),
      };
      this.commit("SET_ROUTING", {
        routing: copiedRouting,
      });
    },

    DELETE_TASK(state, { task }) {
      // console.log(taskId)
      // console.log(state.board.columns)
      var tasks = state.board.columns[1].tasks;
      // var task = tasks.find((task) => task === task);
      var taskIndex = tasks.indexOf(task);
      tasks.splice(taskIndex, 1);
    },
  },
});
