<template>
  <v-app class="app">
    <v-app-bar dense flat color="white">
      <v-btn to="/" icon> <v-icon>mdi-home</v-icon> </v-btn>
      <template v-if="licensed">
        <v-btn to="/job" text rounded v-if="abledJobs"> Jobs </v-btn>
        {{
          abledJobs &&
          (abledTemplates || abledProposSettings || abledCompanySettings)
            ? "|"
            : ""
        }}
        <v-btn to="/templates" text rounded v-if="abledTemplates">
          Templates
        </v-btn>
        {{
          abledTemplates && (abledProposSettings || abledCompanySettings)
            ? "|"
            : ""
        }}
        <v-btn to="/beheer" text rounded v-if="abledProposSettings">
          Beheer
        </v-btn>
        {{ abledProposSettings && abledCompanySettings ? "|" : "" }}
        <v-btn to="/companySettings" text rounded v-if="abledCompanySettings">
          Company Settings
        </v-btn>
      </template>
      <v-spacer />
      <!-- <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on" :color="license.color">
            mdi-information
          </v-icon>
        </template>
        <span>
          <strong>Routing Designer</strong> — license date: {{ license.date }}
        </span>
      </v-tooltip> -->
    </v-app-bar>

    <v-main class="content">
      <v-progress-linear indeterminate v-if="loading && !errorNeeded" />
      <div v-if="errorNeeded">
        <!-- error Needed == when the 2 back-end calls (getLicense & getAbledSettings)
        give back an error -->
        <notConnected />
      </div>
      <template v-else-if="!loading">
        <div class="demo" v-if="licensed">
          <router-view></router-view>
        </div>
        <div v-else>
          <notLicensed />
        </div>
      </template>
    </v-main>

    <v-footer :color="license.color" padless>
      <v-col class="text-center" cols="12">
        <strong>Routing Designer</strong> — license expiry date:
        {{ license.date }}
      </v-col>
    </v-footer>
    <snackBar />
  </v-app>
</template>

<script>
import services from "@/services/services.js";
import notLicensed from "@/components/ErrorPages/NotLicensed.vue";
import notConnected from "@/components/ErrorPages/NotConnected.vue";
import snackBar from "@/components/SnackBar.vue";

export default {
  name: "App",
  beforeCreate() {
    services
      .getLicense()
      .then((response) => {
        // console.log(response.data);
        if (response.data) {
          this.license = response.data;
        }
      })
      .catch((error) => {
        this.$store.commit("SAVE_ERROR", { error });
        console.log(error.response);
        this.error += 1;
      });
    services
      .getAbledSettings()
      .then((response) => {
        // console.log(response.data);
        if (response.data) {
          const abledSettings = response.data;
          this.$store.commit("SAVE_ABLED_SETTINGS", { abledSettings });

          // this.license = response.data;
        }
      })
      .catch((error) => {
        this.$store.commit("SAVE_ERROR", { error });
        console.log(error.response);
        this.error += 1;
      });
  },
  components: {
    notLicensed,
    snackBar,
    notConnected,
  },
  computed: {
    licensed() {
      return this.license && this.license.status != "expired";
    },
    abledJobs() {
      return this.$store.getters.getAbledJobsSetting;
    },
    abledTemplates() {
      return this.$store.getters.getAbledTemplatesSetting;
    },
    abledProposSettings() {
      return this.$store.getters.getAbledProposSettingsSetting;
    },
    abledCompanySettings() {
      return this.$store.getters.getAbledCompanySettingsSetting;
    },
    loading() {
      return !this.license.status;
    },
    errorNeeded() {
      return this.error == 2;
    },
  },
  data() {
    return {
      license: {},
      error: 0,
    };
  },
  methods: {
    goToJob() {
      this.$router.push({ name: "job" });
    },
    goToNewTemplate() {
      const job = null;
      this.$store.commit("SET_JOB", {
        job,
      });
      this.$router.push({ name: "templates/board" });
    },
    goToChooseTemplate() {
      this.$router.push({ name: "templates/routingTemplates" });
    },
  },
};
</script>
